
					@import src/sass/base/_variables
					@import src/sass/base/_mixins
					@import src/sass/base/_animations
			
.social-link
	font-weight: 400
	font-size: 12px
	text-align: center
	letter-spacing: 0.12em
	text-transform: uppercase
	text-decoration: none
	display: flex
	align-items: center
	flex-direction: column
	gap: 10px
	svg
		width: 40px
		display: block
		.color-main
			fill: $color-main
		.color-second
			fill: $color-second
	&[data-theme="dark"]
		&.social-link
			svg
				.color-main
					fill: url(#gradient)
				.color-second
					fill: $color-second_dark
				#gradient
					.stop
						&:nth-child(1)
							stop-color: $color-gradient_1
						&:nth-child(2)
							stop-color: $color-gradient_2