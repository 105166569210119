:root
	--animate-duration: 1s
	--animate-delay: 1s
	--animate-repeat: 1

.animate__animated
	-webkit-animation-duration: 1s
	animation-duration: 1s
	-webkit-animation-duration: var(--animate-duration)
	animation-duration: var(--animate-duration)
	-webkit-animation-fill-mode: both
	animation-fill-mode: both

@-webkit-keyframes slideInUp
	0%
		-webkit-transform: translate3d(0, 200%, 0)
		transform: translate3d(0, 200%, 0)
		visibility: visible
	to
		-webkit-transform: translateZ(0)
		transform: translateZ(0)

@keyframes slideInUp
	0% 
		-webkit-transform: translate3d(0, 200%, 0)
		transform: translate3d(0, 200%, 0)
		visibility: visible

	to 
		-webkit-transform: translateZ(0)
		transform: translateZ(0)

.animate__slideInUp 
	-webkit-animation-name: slideInUp
	animation-name: slideInUp




@-webkit-keyframes slideOutDown
	0%
		-webkit-transform: translateZ(0)
		transform: translateZ(0)

	to
		-webkit-transform: translate3d(0, 200%, 0)
		transform: translate3d(0, 200%, 0)
		visibility: hidden
  
@keyframes slideOutDown 
	0%
		-webkit-transform: translateZ(0)
		transform: translateZ(0)

	to 
		-webkit-transform: translate3d(0, 200%, 0)
		transform: translate3d(0, 200%, 0)
		visibility: hidden

.animate__slideOutDown
	-webkit-animation-duration: .5s
	animation-duration: .5s
	-webkit-animation-name: slideOutDown
	animation-name: slideOutDown

@keyframes flow
	0%
		background-position-x: 0

	50%
		background-position-x: 100%

	100%
		background-position-x: 0

@keyframes shine
	to
		background-position-x: -300%