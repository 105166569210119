
					@import src/sass/base/_variables
					@import src/sass/base/_mixins
					@import src/sass/base/_animations
			
.skill-item
	text-align: center
	width: max-content
	color: $color-text
	font-weight: 400
	font-size: 16px
	letter-spacing: 0.13538em
	text-transform: uppercase
	&__logo
		width: 120px
		height: 120px
		display: block
		margin: 0 auto
		margin-bottom: 22px
	&[data-theme="dark"]
		color: $color-text_dark
		#github_logo, #bem_logo
			filter: invert(100%)

@media (max-width: 768px)
	.skill-item
		width: auto
		&__logo
			margin-bottom: 16px

@media (max-width: 576px)
	.skill-item
		font-size: 12px
		&__logo
			width: 88px
			height: 88px
