
					@import src/sass/base/_variables
					@import src/sass/base/_mixins
					@import src/sass/base/_animations
			
.project-item
	display: flex
	flex-direction: column
	position: relative
	max-width: 520px
	background: $color-card
	box-shadow: 2px 2px 32px rgba(40, 38, 44, 0.15)
	border-radius: 6px
	overflow: hidden
	&__wrapper
		display: flex
		flex-direction: column
		justify-content: space-between
		gap: 15px
		flex-grow: 1
		padding: 30px
		padding-bottom: 20px
	&__title
		font-weight: 600
		font-size: 20px
		color: $color-text
	&__descr
		font-weight: 400
		font-size: 16px
		line-height: 24px
		letter-spacing: 0.04em
		color: $color-text
	&__header
		position: relative
		width: 100%
		aspect-ratio: 260/193
	&__links
		display: flex
		flex-direction: row
		justify-content: flex-end
		flex-wrap: nowrap
		margin-top: auto
		gap: 15px
		a
			text-decoration: none
			color: $color-text
	&[data-theme="dark"]
		background: $color-card_dark
		box-shadow: none
		.project-item
			&__title
				color: $color-text_dark
			&::before
				background-color: #69747E
			&__descr
				color: $color-text_dark

