
					@import src/sass/base/_variables
					@import src/sass/base/_mixins
					@import src/sass/base/_animations
			
.slick-slider
			position: absolute
			width: 100%
			&__img
				display: block
				width: 100%
				height: auto
			&>.slick-arrow
				display: none
				z-index: 5
				width: 40px
				height: 40px
				transition: .5s all
				opacity: 1
				&::before
					font-size: 40px
					opacity: 1
					color: #cdcdcd
				&.slick-prev
					left:25px
				&.slick-next
					right: 25px
			&.dark
				&>.slick-dots
					&>li
						&>button::before
							color: #FFFF


@media (pointer: fine)
	.project-item
		&__header
			&>.slick-slider
				&>.slick-arrow
					display: block
					opacity: .2
					&:hover
						opacity: 1