.button
	background: $color-main
	padding: 0 20px
	height: 45px
	line-height: 45px
	border-radius: 6px
	font-weight: 500
	font-size: 16px
	color: $color-second
	border: none
	cursor: pointer
	&[data-theme="dark"]
		color: $color-second_dark
		background: $color-main_dark
	&_promo
		margin-top: 30px
		// &[data-theme="dark"]
		// 	background: linear-gradient(90deg, $color-gradient_1 , $color-gradient_2 ,$color-gradient_1, $color-gradient_2)
		// 	animation: flow 20s ease-in-out infinite
		// 	background-size: 300%
	&_link
		display: flex
		flex-direction: row
		justify-content: center
		align-items: center
		height: 35px
		padding: 0 10px
		svg
			margin-left: 10px
			path
				fill: $color-second
		&[data-theme="dark"]
			svg
				path
					fill: $color-second_dark
	&_projects
		display: block
		margin: 0 auto
		margin-top: 40px
		width: 305px
		max-width: 100%
		height: 55px
		line-height: 55px
		text-transform: uppercase
	&_submit
		height: 50px
		width: 100%
		display: flex
		align-items: center
		justify-content: center
	&_error
		margin-top: 20px
	&:disabled
		cursor: auto

@media (max-width: 768px)
	.button
		&_promo
			margin: 0 auto
			margin-top: 16px

@media (pointer: fine)
	.button
		transition: box-shadow 0.2s ease-out
		&:hover
			box-shadow: $color-main 0px 0px 15px
		&[data-theme="dark"]
			&:hover
				box-shadow: $color-gradient_2 0px 0px 15px
		&:disabled:hover
			box-shadow: none
