
					@import src/sass/base/_variables
					@import src/sass/base/_mixins
					@import src/sass/base/_animations
			
.text-skeleton
	display: flex
	flex-direction: column
	width: 100%
.skeleton-item
	height: 12px
	margin: 6px 0
	@include skeleton-item