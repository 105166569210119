
					@import src/sass/base/_variables
					@import src/sass/base/_mixins
					@import src/sass/base/_animations
			
.hamburger
	display: none
	position: absolute
	right: 15px
	top: 50%
	transform: translateY(-50%)
	z-index: 20
	width: 32px
	height: 32px
	padding: 6.7px 2.7px
	cursor: pointer
	span
		display: block
		height: 3px
		border-radius: 1.5px
		background: $color-text
		margin-bottom: 5.3px
		margin-left: auto
		transition: 0.3s all
		&:nth-child(1)
			width: 18.7px
		&:nth-child(2)
			width: 26.7px
		&:nth-child(3)
			width: 13.3px
			margin-bottom: 0
	&[data-theme="dark"]
		span
			background: $color-text_dark
	&_active
		span
			&:nth-child(1)
				width: 100%
				transform: rotate(45deg) translate(6px, 4.5px)
				margin-bottom: 0
			&:nth-child(2)
				width: 0px
				opacity: 0
				margin: 0
			&:nth-child(3)
				width: 100%
				transform: rotate(-45deg) translate(-1px, 1px)

@media (max-width: 768px)
	.hamburger
		display: block
