
					@import src/sass/base/_variables
					@import src/sass/base/_mixins
					@import src/sass/base/_animations
			
@import ../../sass/base/font

@import ../../sass/blocks/buttons
@import ../../sass/blocks/title

*
	margin: 0
	padding: 0
	box-sizing: border-box
	font-family: "Montserrat", sans-serif
	transition: color 0.25s ease 0s , background 0.25s ease 0s , filter 0.25s ease 0s, fill 0.25s ease 0s

html
	min-width: 320px
	scrollbar-width: thin
	::-webkit-scrollbar
		width: 10px
	::-webkit-scrollbar-track
		border-radius: 10px
	::-webkit-scrollbar-thumb
		border-radius: 10px

	&[data-theme="light"]
		background: $color-bg
		scrollbar-color: #cdcdcd #F0F0F0
		::selection
			background: $color-second_dark
			color: $color-text_dark
		::-webkit-scrollbar-track
			background: #F0F0F0
		::-webkit-scrollbar-thumb
			background: #cdcdcd
		::-webkit-scrollbar-thumb:hover
			background: #a6a6a6
		::-webkit-scrollbar-thumb:active
			background: #606060
	&[data-theme="dark"]
		background: $color-bg_dark
		scrollbar-color: #69747e #091c2a
		::selection
			background: $color-second
			color: $color-text
		::-webkit-scrollbar-track
			background: #091c2a
		::-webkit-scrollbar-thumb
			background: #69747e
		::-webkit-scrollbar-thumb:hover
			background: #56616b
		::-webkit-scrollbar-thumb:active
			background: #354049

html, body
	height: 100%

#root
	min-height: 100%
	display: flex
	flex-direction: column

input:-webkit-autofill,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover,
input:-webkit-autofill:active
	transition: background-color 600000s 0s, color 600000s 0s
	-webkit-text-fill-color: $color-text
input[data-autocompleted]
	background-color: transparent !important
	-webkit-text-fill-color: $color-text

.container
	width: 1060px
	max-width: 80%
	margin: 0 auto

//toast styles

#root
	--toastify-color-light: #fff
	--toastify-color-dark: #121212
	--toastify-text-color-light: #757575
	--toastify-text-color-dark: #fff
	.Toastify__toast
		border-radius: 8px

@media (max-width: 480px)
	#root
		.Toastify__toast
			border-radius: 0px
