
					@import src/sass/base/_variables
					@import src/sass/base/_mixins
					@import src/sass/base/_animations
			
.spinner
	display: inline-block
	width: 25px
	height: 25px
	border: 4px solid currentColor
	border-radius: 50%
	border-bottom-color: transparent
	animation: spin 1s linear infinite

@keyframes spin
	from
		transform: rotate(0deg)
	to
		transform: rotate(360deg)
