
					@import src/sass/base/_variables
					@import src/sass/base/_mixins
					@import src/sass/base/_animations
			
.header
	height: 100px
	.nav
		position: fixed
		width: 100%
		height: 100px
		top: 0
		z-index: 10
		display: flex
		align-items: center
		background: $color-bg
		&-link
			text-align: center
			font-weight: 500
			display: block
			height: 44px
			line-height: 44px
			position: relative
			font-size: 16px
			color: $color-text
			text-decoration: none
			&:before
				content: ""
				display: block
				position: absolute
				bottom: 9px
				left: 50%
				transform: translateX(-50%)
				height: 3px
				background: $color-main
				border-radius: 1.5px
				width: 0%
				transition: 0.2s width ease-out
			&.active
				color: $color-main
				&:before
					width: 100%

		&__wrapper
			display: flex
			flex-direction: row
			justify-content: space-between
			align-items: center
		&__btns
			display: flex
			flex-direction: row
			align-items: center
			&>div
				margin-right: 80px
				width: 75px
				.nav-link
					width: min-content
					margin: 0 auto
			button
				margin-right: 20px
				&:last-child
					margin-right: 0
		.menu
			display: flex
			flex-direction: row
			justify-content: center
			align-items: center
			list-style-type: none
			&__item
				margin-left: 90px
				&:nth-child(1)
					display: none
				&:nth-child(2)
					margin-left: 0
	&[data-theme="dark"]
		.nav
			background: $color-bg_dark
			&-link
				color: $color-text_dark
				&:before
					background: $color-main_dark
				&.active
					@include header-text_dark

@media (max-width: 768px)
	.header
		height: 72px
		.nav
			&__btns
				&>div
					display: none
					height: 72px
			&-link
				font-size: 20px
				height: 50px
				line-height: 50px
				&:before
					display: none
			.menu
				background: $color-bg-transpparent
				left: -100%
				top: 0
				width: 65%
				height: 100vh
				position: absolute
				flex-direction: column
				transition: 0.5s left

				--webkit-backdrop-filter: saturate(180%) blur(10px)
				-webkit-backdrop-filter: saturate(180%) blur(10px)
				backdrop-filter: saturate(180%) blur(10px)
				&_active
					left: 0%
				&__item
					margin: 10px 0
					width: 100%
					&:nth-child(1)
						display: list-item

		&[data-theme="dark"]
			.menu
				background: $color-bg-transpparent_dark

@media (pointer: fine)
	.header
		.nav
			&-link
				&:hover
					color: $color-main
					transition: color 0.2s ease-out
		&[data-theme="dark"]
			.nav
				&-link
					&:hover
						color: $color-text_dark
						@include header-text_dark
