
					@import src/sass/base/_variables
					@import src/sass/base/_mixins
					@import src/sass/base/_animations
			
.error-screen
	display: flex
	flex-direction: column
	align-items: center
	justify-content: center
	text-align: center
	padding: 90px 0
	h1
		font-size: 2.5rem
		margin-bottom: 1rem
		color: $color-error
	p
		font-size: 1.25rem
		margin-bottom: 1rem
		color:$color-text
	&[data-theme="dark"]
		p
			color: $color-text_dark