
					@import src/sass/base/_variables
					@import src/sass/base/_mixins
					@import src/sass/base/_animations
			
.promo
	padding: 70px 0
	&__wrapper
		display: flex
		justify-content: space-between
		align-items: center
		flex-direction: row
	&__subheader
		font-family: 'Tinos'
		font-weight: 400
		font-size: 20px
		color: $color-text
	&__header
		margin: 0
		margin-top: 10px
		font-weight: 600
		font-size: 72px
		color: $color-main
		user-select: none
	&__descr
		margin-top: 10px
		font-weight: 400
		font-size: 16px
		line-height: 24px
		letter-spacing: 0.04em
		color: $color-text
	&__link
		text-decoration: none
	&__text
		max-width: 520px
		width: 100%
	&__img-wrapper
		position: relative
		max-width: 490px
		min-width: 55%
		height: auto
	&__img
		width: 100%
		height: auto
		display: block
		transition: all .4s
		&.light
			position: absolute
			opacity: 1
		&.dark
			opacity: 0


	&[data-theme="dark"]
		.promo
			&__subheader
				color: $color-text_dark
			&__header
				@include header-text-animated_dark
			&__descr
				color: $color-text_dark
			&__img
				&.light
					opacity: 0
				&.dark
					opacity: 1

@media (max-width: 992px)
	.promo
		&__header
			font-size: 55px

@media (max-width: 768px)
	.promo
		padding: 30px 0
		&__wrapper
			flex-direction: column-reverse
			text-align: center
		&__img-wrapper
			min-width: 100%
		&__text
			margin-top: 60px

@media (max-width: 576px)
	.promo
		&__subheader
			font-size: 16px
			text-transform: uppercase
		&__header
			font-size: 40px
