$color-main: #5222D0
$color-border: #534084
$color-second: #DBFFFF
$color-text: #1F2626
$color-card: #F5F2FD
$color-bg: #FAFAFA
$color-bg-transpparent: rgba(250, 250, 250, .5)
$color-bg-hover: rgba(0,0,0,.1)
$color-error: #C05353

$color-gradient_1: #00F5A0
$color-gradient_2: #00D9F5
$color-main_dark: linear-gradient(90deg, $color-gradient_1 0%, $color-gradient_2 100%)
$color-second_dark: #252728
$color-text_dark: #BDEBEA
$color-card_dark: #222525
$color-bg_dark: #041F31
$color-bg-transpparent_dark: rgba(4, 31, 49, .8)
$color-bg-hover_dark: rgba(255,255,255,.1)
