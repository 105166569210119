@font-face
	font-family: 'Montserrat'
	src: local('Montserrat')
	src: url('/fonts/Montserrat-Regular.woff2') format('woff2')
	src: url('/fonts/Montserrat-Regular.woff') format('woff')
	font-weight: 400
	font-style: normal

@font-face
	font-family: 'Montserrat'
	src: local('Montserrat')
	src: url('/fonts/Montserrat-Medium.woff2') format('woff2')
	src: url('/fonts/Montserrat-Medium.woff') format('woff')
	font-weight: 500
	font-style: normal

@font-face
	font-family: 'Montserrat'
	src: local('Montserrat')
	src: url('/fonts/Montserrat-SemiBold.woff2') format('woff2')
	src: url('/fonts/Montserrat-SemiBold.woff') format('woff')
	font-weight: 600
	font-style: normal

@font-face
	font-family: 'Tinos'
	src: local('Tinos')
	src: url('/fonts/Tinos-Regular.woff2') format('woff2')
	src: url('/fonts/Tinos-Regular.woff') format('woff')
	font-weight: 400
	font-style: normal
