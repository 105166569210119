
					@import src/sass/base/_variables
					@import src/sass/base/_mixins
					@import src/sass/base/_animations
			
.footer
	padding: 90px 0
	color: $color-text
	background-color: $color-bg
	a
		color: $color-text
	&__contacts
		max-width: 320px
		margin: 0 auto
		display: flex
		flex-direction: row
		justify-content: space-between
	&__links
		max-width: 160px
		margin: 0 auto
		margin-top: 50px
		display: flex
		flex-direction: row
		justify-content: space-between
		a
			font-weight: 400
			font-size: 14px
			text-decoration: none
	&__signature
		color: $color-text
		margin: 0 auto
		margin-top: 50px
		text-align: center
		text-transform: uppercase
		font-weight: 400
		font-size: 14px
		letter-spacing: 0.04em
		
	&[data-theme="dark"]
		color: $color-text_dark
		background-color: $color-bg_dark
		.footer
			&__signature
				color: $color-text_dark
		a
			color: $color-text_dark

@media (max-width: 768px)
	.footer
		padding: 70px 0
