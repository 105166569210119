
					@import src/sass/base/_variables
					@import src/sass/base/_mixins
					@import src/sass/base/_animations
			
.main
	display: flex
	flex-direction: column
	justify-content: center
	flex: 1 1 auto
	background-color: $color-bg
	&[data-theme="dark"]
		background-color: $color-bg_dark