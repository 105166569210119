
					@import src/sass/base/_variables
					@import src/sass/base/_mixins
					@import src/sass/base/_animations
			
.theme-switcher
	background: none
	border: none
	padding: 0
	border-radius: 50%
	cursor: pointer
	touch-action: manipulation
	-webkit-tap-highlight-color: transparent
	outline-offset: 5px
	width: 32px
	height: 32px
	display: flex
	justify-content: center
	align-items: center
	.sun-and-moon
		stroke-linecap: round
		&.moon,.sun,.sun-beams
			transform-origin: center center
		.sun
			transition: transform .5s cubic-bezier(0.5, 1.25, 0.75, 1.25)
			fill: $color-text
		.sun-beams
			opacity: 1
			transform: rotateZ(0deg)
			transition-duration: .15s
			transition: transform .5s cubic-bezier(0.5, 1.5, 0.75, 1.25), opacity .5s cubic-bezier(0.25, 0, 0.3, 1)
			stroke-width: 2px
			stroke: $color-text
		.moon > circle
			transition: transform .25s cubic-bezier(0, 0, 0, 1)
			@supports (cx: 1)
				transition: cx .25s cubic-bezier(0.9, 0, 0.1, 1)

	&[data-theme="dark"]
		.sun-and-moon
			.sun
				transform: scale(1.75)
				fill: $color-text_dark
				transition-timing-function: cubic-bezier(0.25, 0, 0.3, 1)
				transition-duration: .25s
			.sun-beams
				opacity: 0
				transform: rotateZ(-25deg)
				transition-duration: .15s
				stroke: $color-text_dark
			.moon > circle
				transform: translateX(-7px)
				transition-delay: .25s
				transition-duration: .5s
				@supports (cx: 1)
					transform: translateX(0)
					cx: 17

@media (max-width: 768px)
	.theme-switcher
		width: 32px
		height: 32px
		.sun-and-moon
			width: 32px
			height: 32px

@media (pointer: fine)
	.theme-switcher
		&:hover
			background: $color-bg-hover
		&[data-theme="dark"]
			&:hover
				background: $color-bg-hover_dark
