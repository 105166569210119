
					@import src/sass/base/_variables
					@import src/sass/base/_mixins
					@import src/sass/base/_animations
			
.project-item-list
	padding: 70px 0
	&__content
		margin-top: 30px
		display: grid
		grid-template-columns: 1fr 1fr
		gap: 40px 20px
		justify-items: center
		justify-content: center

@media (max-width: 768px)
	.project-item-list
		padding: 30px 0
		&__content
			// grid-template-columns: repeat(auto-fit, minmax(256px, 1fr))
			grid-template-columns: 1fr
