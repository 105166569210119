
					@import src/sass/base/_variables
					@import src/sass/base/_mixins
					@import src/sass/base/_animations
			
.skill-item-skeleton
	&__logo
		width: 120px
		height: 120px
		margin-bottom: 22px
		@include skeleton-item
	.skeleton-item
		height: 16px


@media (max-width: 576px)
	.skill-item-skeleton
		&__logo
			width: 88px
			height: 88px
			margin-bottom: 16px
		.skeleton-item
			height: 12px