
					@import src/sass/base/_variables
					@import src/sass/base/_mixins
					@import src/sass/base/_animations
			
.about-me
	padding: 70px 0
	&__wrapper
		padding-left: 22px
		max-width: 840px
	&__text
		margin-top: 20px
		font-weight: 400
		font-size: 18px
		line-height: 26px
		letter-spacing: 0.04em
		color: $color-text
		position: relative
		&:before
			position: absolute
			content: ""
			display: block
			width: 5px
			border-radius: 2.5px
			height: 96%
			background: $color-main
			left: -22px
			top: 50%
			transform: translateY(-50%)
	&[data-theme="dark"]
		.about-me
			&__text
				color: $color-text_dark
				&:before
					background: linear-gradient(0deg, $color-gradient_1 0%, $color-gradient_2 100%)

@media (max-width: 768px)
	.about-me
		padding: 30px 0
