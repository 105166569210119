
					@import src/sass/base/_variables
					@import src/sass/base/_mixins
					@import src/sass/base/_animations
			
.contact-form
	width: 530px
	max-width: 100%
	padding: 50px
	box-sizing: content-box
	&_main
		margin: 0 auto
		padding: 90px 0
	&__field
		position: relative
		border-radius: 6px
		&[data-disabled="true"]
			background: rgba(0, 0, 0, 0.1)
		.descr
			font-weight: 400
			font-size: 14px
			color: $color-text
			position: absolute
			top: -20px
			left: 0
		.error
			position: absolute
			bottom: -20px
			right: 0
			font-weight: 500
			font-size: 14px
			color: $color-error
	input, textarea
		display: block
		width: 100%
		height: 50px
		margin-bottom: 50px
		padding: 0 20px
		font-weight: 500
		font-size: 16px
		color: $color-text
		border-radius: 6px
		border: 1px solid $color-border
		outline: transparent
		background: transparent
		&:placeholder
			color: #919B9B
		&:focus
			border: 2px solid $color-main
		&:selected
			background: transparent
	textarea
		height: 160px
		resize: none
		margin-bottom: 20px
		padding: 15px 20px
		line-height: 20px
		&:focus
			border: 2px solid $color-main

	&[data-theme="dark"]
		.contact-form
			&__field
				.descr
					color: $color-text_dark
		input, textarea
			color: $color-text_dark
			border: 1px solid $color-text_dark
			&:focus
				@include color-border_dark
		input:-webkit-autofill
			-webkit-text-fill-color: $color-text_dark
		input:-webkit-autofill:focus,
		input:-webkit-autofill:active
			border: 2px solid $color-gradient_1
			-webkit-text-fill-color: $color-text_dark
		input:-webkit-autofill:disabled
			border: 1px solid $color-text_dark
			-webkit-text-fill-color: $color-text_dark

@media (max-width: 425px)
	.contact-form
		input, textarea
			margin-bottom: 35px
