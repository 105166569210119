@mixin header-text_dark
	background: $color-main_dark
	-webkit-background-clip: text
	-webkit-text-fill-color: transparent

@mixin header-text-animated_dark
	-webkit-text-fill-color: transparent
	background: linear-gradient(90deg, $color-gradient_1 , $color-gradient_2 ,$color-gradient_1, $color-gradient_2)
	-webkit-background-clip: text
	animation: flow 20s ease-in-out infinite
	background-size: 300%

@mixin color-border_dark
	background: linear-gradient($color-bg_dark, $color-bg_dark) padding-box, $color-main_dark border-box
	border: 2px solid transparent

@mixin skeleton-item
	background: linear-gradient(110deg, #bebebe 8%, #f5f5f5 18%, #bebebe 33%)
	background-size: 300% 100%
	animation: 2.5s shine linear infinite
	opacity: .5
	border-radius: 6px