
					@import src/sass/base/_variables
					@import src/sass/base/_mixins
					@import src/sass/base/_animations
			
.project-item-skeleton
	display: flex
	flex-direction: column
	position: relative
	width: 100%
	max-width: 520px
	background: $color-card
	box-shadow: 2px 2px 32px rgba(40, 38, 44, 0.15)
	border-radius: 6px
	&__header
		position: relative
		width: 100%
		aspect-ratio: 260/193
		border-radius: 6px
		@include skeleton-item
	&__wrapper
		display: flex
		flex-direction: column
		justify-content: space-between
		gap: 15px
		flex-grow: 1
		padding: 30px
		padding-bottom: 20px
	&__title
		height: 23px
		@include skeleton-item
	&__technologies
		height: 30px
		@include skeleton-item
	&__links
		height: 35px
		display: flex
		flex-direction: row
		justify-content: flex-end
		flex-wrap: nowrap
		margin-top: auto
		gap: 15px
	&__link
		height: 100%
		width: 90px
		@include skeleton-item
	&[data-theme="dark"]
		background: $color-card_dark
		box-shadow: none