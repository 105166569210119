
					@import src/sass/base/_variables
					@import src/sass/base/_mixins
					@import src/sass/base/_animations
			
.project-item-technologies
	overflow: overlay hidden
	width: inherit
	height: 40px
	position: relative
	.simplebar-content
		display: flex
	.simplebar-scrollbar
		&::before
			background-color: #cdcdcd
	.simplebar-horizontal
		height: 8px !important
	&__list
		display: flex
		gap: 12px
		margin-bottom: 10px
	&__wrapper
		position: absolute
		width: 100%
		top: 0
		left: 0
	&__technology
		height: 30px
		line-height: 30px
		text-align: center
		padding: 0 16px
		background: $color-main
		border-radius: 4px
		font-weight: 400
		font-size: 10px
		letter-spacing: 0.12em
		text-transform: uppercase
		color: $color-second
		white-space: nowrap
		&:last-child
			margin-right: 0

	&[data-theme="dark"]
		.project-item-technologies
			&__technology
				background: $color-main_dark
				color: $color-second_dark