
					@import src/sass/base/_variables
					@import src/sass/base/_mixins
					@import src/sass/base/_animations
			
.lang-switcher
	background: transparent
	border: none
	padding: 0
	cursor: pointer
	font-size: 16px
	font-weight: 500
	display: flex
	align-items: center
	width: 50px
	height: 32px
	border-radius: 8px
	overflow: hidden
	&[data-lang="en"]
		div
			transform: translateX(0)
			transition: transform .3s ease-in-out
	&[data-lang="ru"]
		div
			transform: translateX(-50px)
			transition: transform .3s ease-in-out

	div
		margin: 0 auto
	span
		margin: 0 7px
		color: $color-text
	&[data-theme="dark"]
		span
			color: $color-text_dark

@media (pointer: fine)
	.can-hover
		.lang-switcher
			&:hover
				background: $color-bg-hover
			&[data-theme="dark"]
				&:hover
					background: $color-bg-hover_dark
